<script setup>
	var I18n = window.I18n;
</script>
<template>
	<div class="cookie-banner" v-if="showCookies">
		<div>
			{{ I18n.t('cookies_message') }}
			<a class="link" :href="'/' + I18n.locale + '/help/cookies_policy'">{{ I18n.t('cookies_policy') }}</a>
		</div>
		<button @click="acceptCookies()">{{ I18n.t('accept') }}</button>
	</div>
</template>

<script>
export default {
  data() {
	  return {
		showCookies: true
	  }
  },
  props: {
  },
  methods: {
	acceptCookies() {
		// Puedes utilizar cookies o localStorage para almacenar la aceptación del usuario.
		// Por ejemplo, si usas localStorage:
		localStorage.setItem('cookiesAccepted', 'true');
		this.showCookies = false;
	}
 },
 mounted() {
	this.showCookies = localStorage.getItem('cookiesAccepted') != 'true';
  }
}
</script>

<style scoped>
	@import "../assets/stylesheets/cookie_banner.css"
</style>

