<script setup>
	var notifications = window.notifications;
</script>
<template>
	<div :class="{'toast-porra alert alert-info' : true, 'hidden' : notifications.updatingList.length == 0}" v-cloak>
		<div v-for="linea in notifications.updatingList" :key="linea.id" class="v-cloak--hidden">
			<span>{{ linea.message }}</span>
			&nbsp;
			<span>
				<span :class="{'fas fa-spinner fa-spin' : !linea.status, 'far fa-check-circle win': linea.status == 'ok', 'fas fa-question-circle win': linea.status == 'info', 'far fa-times-circle loss': linea.status == 'error'}"></span>
			</span>
		</div>
	</div>
</template>

<script>
export default {
  data() {
	  return {
	  }
  },
  props: {
	  flash: Array
  },
  methods: {
	railsKeyToStatus(key) {
		if(['success'].includes(key)) {
			return('ok');
		}
		if(['notice'].includes(key)) {
			return('info');
		}
		return('error');
	}
 },
 mounted: function () {
	notifications.registerObserver(this);
	// Messages that come from rails controlles
 	if(flash != null) {
	    flash.forEach((message, key) => notifications.toast(Date.now(), message, this.railsKeyToStatus(key)));
 	}
  }
}
</script>

<style scoped>
</style>

