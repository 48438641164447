import { createApp } from 'vue/dist/vue.esm-bundler.js';
import Alerts from '../alerts.vue';
import Notifications from '../notifications.vue';
import CookieBanner from '../cookie_banner.vue';

var alertsApp = createApp({});
alertsApp.component('alerts', Alerts);
alertsApp.mount('#alerts');

var notificationsApp = createApp(Notifications);
notificationsApp.mount('#notifications');

var cookieBannerApp = createApp({});
cookieBannerApp.component('cookie-banner', CookieBanner);
cookieBannerApp.mount('#cookie-banner');
