<script setup>
import ApiService from "./packs/modules/ApiService";
var I18n = window.I18n;
var notifications = window.notifications;
var alerts = window.alerts;
var authenticityToken = window.authenticityToken;

</script>

<template>
	<div>
		<div class="" v-cloak>
			<nav class="porra-navbar" id="main-navbar">
			    <ul class="">
			      <li class="nav-item no-gutters">
					<a href="/">
						<!--<img height="128" src="/porra-logo4.png">-->
						<img height="64" src="/porra-logo2.png">
					</a>
			      </li>
				</ul>
				<ul class="porra-navbar-buttons">
					<li class="porra-navbar-button">
						<a :href="'/' +  I18n.locale + '/help/about'" class="nav-item nav-link" :title="I18n.t('about')">
							<span class="fa fa-question-circle fa-2x"></span>
						</a>
					</li>
					<li class="porra-navbar-button">
						<span class="nav-item nav-link fa-stack pointer" @click="showSidePanel()">
							<span class="fa fa-user-circle fa-2x"></span>
							<span class="number-stack alert-number-stack v-cloak--hidden" v-if="alerts.hasAlerts()">{{ alerts.numberOfAlerts() }}</span>
						</span>
					</li>
				</ul>
			</nav>
		</div>
	  	<div class="side-column-background v-cloak--hidden" v-if="isActiveSidePanel" @click="hideSidePanel()">
	  	</div>
		<transition name="side-column">
		  	<div class="side-column v-cloak--hidden animate__animated animate__slideInRight" v-if="isActiveSidePanel">
		  		<div class="side-column-header b-bottom-light">
			  		<div>
		  				<template v-if="isUserSignedIn">
							{{ currentUser.name }}
		  				</template>
					</div>
		  			<span>
		  				<span class="fas fa-times pointer" @click="hideSidePanel()"></span>
	  				</span>
		  		</div>
			    <div class="side-column-body">
					<div class="side-column-body-header b-bottom-light" v-if="isUserSignedIn">
						<div class="suscription" v-if="suscriptionAvailable">
							<!-- Rounded switch -->
							<label class="switch">
							  <input type="checkbox" :checked="isSuscribed" @click="toggleSubscription()">
							  <span class="slider round"></span>
							</label>
							<span>
								{{ I18n.t("notify_pending_results") }}
							</span>
						</div>
					</div>
					<div class="side-column-body-body">
						<div>
						<template v-if="isUserSignedIn">
 							<a class="side-column-item" :title="I18n.t('profile')" :href="'/' + I18n.locale + '/users/edit'">
								<span class="fa fa-wrench"></span> {{ I18n.t('profile') }}
							</a>
							<div v-if="alerts.hasAlerts()">
				            	<a v-for="alert in alerts.alertsList" :title="I18n.t('my_bets')" class="side-column-item"
				            		:href="'/' + I18n.locale + '/result_bets/new?league_id=' + alert.league_id + '&matchday=' + alert.matchday">
				            		<div>
										<div class="side-column-alert-league">
											<span :class="{'link-fa link-shadow': true, 'far fa-edit warning': alert.alert_type == 1, 'fa fa-hand-point-right danger': alert.alert_type == 2, 'fa fa-futbol danger': alert.alert_type == 3}"></span> {{ alert.message }}
					            		</div>
					            		<div class="side-column-alert-competition">
					            			{{ alert.league_name }}
					            		</div>
				            		</div>
								</a>
							</div>
							<template v-if="isUserSignedIn && isGlobalAdminUser">
								<div class="side-column-item" @click="updateMatches()">
									<span class="fa fa-arrow-alt-circle-down"></span> Update
								</div>
								<div class="side-column-item" @click="updateCompetitions()">
									<span class="fa fa-arrow-alt-circle-down"></span> Update All
								</div>
								<div class="side-column-item" v-for="competition in competitions" :key="competition.id" @click="updateCompetition(competition.id)">
									<span class="fa fa-arrow-alt-circle-down"></span> {{ competition.id }} - {{ competition.name }}
								</div>
								<div class="side-column-item" @click="sendNotifications()">
									<span class="fa fa-envelope"></span> Notify
								</div>
							</template>
						</template>
						<template v-else>
							<a class="side-column-item" :title="I18n.t('sign_in')" :href="'/' + I18n.locale + '/users/sign_in'">
								<span class="fa fa-sign-in-alt"></span> {{ I18n.t('sign_in') }}
							</a>
 							<a class="side-column-item" :title="I18n.t('sign_up')" :href="'/' + I18n.locale + '/users/sign_up'">
								<span class="fa fa-user"></span> {{ I18n.t('sign_up') }}
							</a>
						</template>
						</div>
	
				    </div>
					<div class="side-column-body-footer" v-if="isUserSignedIn"> 
						<div class="side-column-item">
							<a class="no-deco" :title="I18n.t('sign_out')" rel="nofollow" data-method="delete" :href="'/' + I18n.locale + '/users/sign_out'">
								<span class="fa fa-sign-out-alt"></span> {{ I18n.t('sign_out') }}
							</a>
						</div>
					</div>
			    </div>
			</div>
	  	</transition>
	</div>
</template>

<script>
export default {
  data() {
	  return {
	    isSuscribed: false,
	    isActiveSidePanel: false,
		competitions: []
	  }
  },
  props: {
	isUserSignedIn: Boolean,
	currentUser: Object
  },
  computed: {
	isGlobalAdminUser() {
		return this.isUserSignedIn && this.currentUser && this.currentUser.is_admin
	},
  	suscriptionAvailable: function() {
  		return ('serviceWorker' in navigator) && ('PushManager' in window);
  	}
  },
  methods: {
  	showSidePanel: function() {
  		this.isActiveSidePanel = true;
  	},
  	hideSidePanel: function() {
  		this.isActiveSidePanel = false;
  	},
	updateMatches: function() {
		this.hideSidePanel();
	  	var url = '/update/update';
	  	this.innerPostAction(-100, 'Actualizando partidos', url);
	},
	updateCompetitions: function() {
		this.hideSidePanel();
	  	var url = '/update/update_all';
	  	this.innerPostAction(-200, 'Actualizando competiciones', url);
	},
	updateCompetition: function(competitionId) {
		this.hideSidePanel();
	  	var url = '/update/update_competition/' + competitionId;
	  	this.innerPostAction(-200, 'Actualizando competición ' + competitionId, url);
	},
	sendNotifications: function() {
		this.hideSidePanel();
	  	var url = '/update/notify';
	  	this.innerPostAction(-300, 'Enviando notificaciones', url);
	},
	innerPostAction: function(id, message, url) {
		// Comprobar que no este la accion ya en curso
		var current = notifications.updatingList.filter(line => line.id == id);
		if (current.length == 0) {
			// Meter en la lista de tareas en curso
			notifications.push(id, message);
		  	ApiService.post(url, {authenticity_token: authenticityToken},
				(response) => {
		  			// Quitar de la lista de cosas pendientes
		  			notifications.update(id, response.status);
		  		},
				(error) => {
		  			// Quitar de la lista de cosas pendientes
		  			notifications.update(id, error.response.status);
				},
				() => {}
			);
		}
	},
  	toggleSubscription: function() {
  		var thisApp = this;
  		if (!this.isSuscribed) {
			// When serviceWorker is supported, installed, and activated,
			// subscribe the pushManager property with the vapidPublicKey
			window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
			  serviceWorkerRegistration.pushManager.subscribe({
			    userVisibleOnly: true,
			    applicationServerKey: window.vapidPublicKey
			  }).then((subscription) => {
					ApiService.suscribe(subscription.toJSON(),
						(response) => {
							console.log("Successfully suscribed!");
					  		thisApp.isSuscribed = true;
				  		},
						(error) => {
					  		thisApp.isSuscribed = false;
						},
						() => {
						}
					);
				});
			});
  		} else {
			window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
				serviceWorkerRegistration.pushManager.getSubscription()
					.then((subscription) => {
						subscription.unsubscribe().then(function(successful) {
							ApiService.unsuscribe(subscription.toJSON(),
								(response) => {
									console.log("Successfully unsuscribed");
							  		thisApp.isSuscribed = false;
						  		},
								(error) => {
							  		thisApp.isSuscribed = true;
								},
								() => {
								}
							);
					    }).catch(function(e) {
					    	// Unsubscription failed
							console.log(e);
					  		thisApp.isSuscribed = true;
					    })
					});
			});
  		}
  	}
  },
  mounted: function() {
	var thisApp = this;
	// Cableado
	if(this.isGlobalAdminUser) {
		ApiService.getActiveCompetitions(
				(response) => {
			  		this.competitions = response.data;
		  		}, (error) => {},() => {}
			);
	}
	if(this.suscriptionAvailable && window.navigator && window.navigator.serviceWorker) {
		window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
			serviceWorkerRegistration.pushManager.getSubscription()
				.then((subscription) => {
					thisApp.isSuscribed = subscription != null;
				});
		});
	};
	alerts.registerObserver(this);
  }
}
</script>

<style scoped>
	@import "../assets/stylesheets/alerts.css"
</style>

